import { Component, Injector, Input, ViewEncapsulation } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    templateUrl: './default-logo.component.html',
    selector: 'default-logo',
    encapsulation: ViewEncapsulation.None,
})
export class DefaultLogoComponent extends AppComponentBase{
    @Input() customHrefClass = '';
    @Input() skin = null;

    defaultLogo = AppConsts.appBaseUrl + '/assets/common/images/app-logo-on-light.png';
    remoteServiceBaseUrl: string = AppConsts.remoteServiceBaseUrl;

    constructor(injector: Injector) {
        super(injector);
    }
}
