<a href="/" [class]="customHrefClass">
    <img
        alt="Logo"
        *ngIf="!appSession.tenant || !appSession.tenant.HasLogo()"
        [src]="defaultLogo"
        height="60"
    />
    <img
        alt="Logo"
        *ngIf="appSession.tenant && appSession.tenant.HasLogo()"
        [src]="
            remoteServiceBaseUrl +
            '/TenantCustomization/GetTenantLogo?skin=' +
            currentTheme.baseSettings.menu.asideSkin +
            '&tenantId=' +
            appSession.tenant.id
        "
        height="60"
    />
</a>
