import { Component, Injector, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ImpersonationService } from '@app/admin/users/impersonation.service';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { LinkedAccountService } from '@app/shared/layout/linked-account.service';
import { AppConsts } from '@shared/AppConsts';
import { ChangeUserLanguageDto, ProfileServiceProxy, UserLinkServiceProxy } from '@shared/service-proxies/service-proxies';
import { AbpMultiTenancyService, AbpSessionService } from 'abp-ng2-module';
import { ThemesLayoutBaseComponent } from '../themes/themes-layout-base.component';

@Component({
    selector: 'user-menu',
    templateUrl: './user-menu.component.html',
})
export class UserMenuComponent extends ThemesLayoutBaseComponent implements OnInit {
    @Input() iconOnly = false;

    @Input() togglerCssClass = 'cursor-pointer symbol symbol-35px symbol-md-40px';
    @Input() profileImageCssClass = '';
    //TODO@Metronic8 -> we may delete this.
    @Input() textCssClass = 'text-dark-50 fw-bolder fs-base d-none d-md-inline me-3';
    @Input() symbolCssClass = 'symbol symbol-lg-30px symbol-20px';
    @Input() symbolTextCssClass = 'symbol-label fs-2 fw-bold bg-success text-inverse-success';

    usernameFirstLetter = '';

    profilePicture = AppConsts.appBaseUrl + '/assets/common/images/default-profile-picture.png';
    shownLoginName = '';
    tenancyName = '';
    userName = '';
    userRoleName = '';
    fullName = '';
    emailAddress = '';

    isImpersonatedLogin = false;
    isMultiTenancyEnabled = false;
    showDeleteActions: boolean = this.setting.getBoolean('App.User.ShowDeleteActions');
    knowledgeCenterLink: string;

    mQuickUserOffcanvas: any;

    public constructor(
        injector: Injector,
        private _router: Router,
        private _linkedAccountService: LinkedAccountService,
        private _abpMultiTenancyService: AbpMultiTenancyService,
        private _profileServiceProxy: ProfileServiceProxy,
        private _userLinkServiceProxy: UserLinkServiceProxy,
        private _authService: AppAuthService,
        private _impersonationService: ImpersonationService,
        private _abpSessionService: AbpSessionService,
        _dateTimeService: DateTimeService
    ) {
        super(injector, _dateTimeService);
    }

    ngOnInit(): void {
        this.isImpersonatedLogin = this._abpSessionService.impersonatorUserId > 0;
        this.isMultiTenancyEnabled = this._abpMultiTenancyService.isEnabled;
        this.setCurrentLoginInformations();
        this.getProfilePicture();
        this.registerToEvents();
        this.usernameFirstLetter = this.appSession.user.userName.substring(0, 1).toUpperCase();
    }


    linkClick() {
        if (this.permission.isGranted('Pages.KnowledgeCenter')) {
            window.open(this.knowledgeCenterLink, '_blank');
            this._userLinkServiceProxy.clickKnowledgeLink().subscribe();
        }
    }

    changeLanguage(languageName: string): void {
        const input = new ChangeUserLanguageDto();
        input.languageName = languageName;

        this._profileServiceProxy.changeLanguage(input).subscribe(() => {
            abp.utils.setCookieValue(
                'Abp.Localization.CultureName',
                languageName,
                new Date(new Date().getTime() + 5 * 365 * 86400000), //5 year
                abp.appPath
            );

            window.location.reload();
        });
    }

    showDeleteActionToggle() {
        this._profileServiceProxy.saveSettings(!this.showDeleteActions).subscribe(() => {

            window.location.reload();
        });
    }

    setCurrentLoginInformations(): void {
        this.shownLoginName = this.appSession.getShownLoginName();
        this.tenancyName = this.appSession.tenancyName;
        this.userName = this.appSession.user.userName;
        this.emailAddress = this.appSession.user.emailAddress;
        this.userRoleName = this.appSession.user.userRoleName === 'Company User' ? 'Team Member' : this.appSession.user.userRoleName;
        this.fullName = this.appSession.user.name + ' ' + this.appSession.user.surname;
    }

    getProfilePicture(): void {
        this._profileServiceProxy.getProfilePicture().subscribe((result) => {
            if (result && result.profilePicture) {
                this.profilePicture = 'data:image/jpeg;base64,' + result.profilePicture;
            }
        });
    }

    notificationSettingsModalshow() {
        abp.event.trigger('app.show.changeNotificationSettingsModal');
    }

    showLoginAttempts(): void {
        this._router.navigate(['/app/admin/login-attempts']);
    }

    showUserDelegations(): void {
        abp.event.trigger('app.show.userDelegationsModal');
    }

    changePassword(): void {
        abp.event.trigger('app.show.changePasswordModal');
    }

    changeProfilePicture(): void {
        abp.event.trigger('app.show.changeProfilePictureModal');
    }

    changeMySettings(): void {
        abp.event.trigger('app.show.mySettingsModal');
    }

    registerToEvents() {
        this.subscribeToEvent('profilePictureChanged', () => {
            this.getProfilePicture();
        });

        this.subscribeToEvent('app.onMySettingsModalSaved', () => {
            this.onMySettingsModalSaved();
        });
    }

    logout(): void {
        this._authService.logout();
    }

    onMySettingsModalSaved(): void {
        this.shownLoginName = this.appSession.getShownLoginName();
    }

    backToMyAccount(): void {
        this._impersonationService.backToImpersonator();
    }

    downloadCollectedData(): void {
        this._profileServiceProxy.prepareCollectedData().subscribe(() => {
            this.message.success(this.l('GdprDataPrepareStartedNotification'));
        });
    }
}
