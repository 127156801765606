<div [@routerTransition] class="d-flex flex-column flex-root app-root" id="kt_app_root">
    <!--begin::Page-->
    <div class="app-page flex-column flex-column-fluid" id="kt_app_page">
        <!--begin::Header-->
        <div id="kt_app_header" class="app-header">
            <!--begin::Header container-->
            <div class="app-container container-fluid d-flex align-items-stretch justify-content-between"
                id="kt_app_header_container">
                <!--begin::sidebar mobile toggle-->
                <div class="d-flex align-items-center d-lg-none ms-n2 me-2" title="Show sidebar menu">
                    <div class="btn btn-icon btn-active-color-primary m-5" id="kt_app_sidebar_mobile_toggle">
                        <span></span>
                    </div>
                </div>
                <!--end::sidebar mobile toggle-->
                <!--begin::Logo-->
                <div class="app-sidebar-logo" id="kt_app_sidebar_logo">
                    <!--begin::Sidebar toggle-->
                    <div *ngIf="appSession.theme.baseSettings.menu.allowAsideMinimizing" id="kt_app_sidebar_toggle"
                        class="app-sidebar-toggle btn btn-icon btn-sm"
                        data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body"
                        data-kt-toggle-name="app-sidebar-minimize">
                        <span></span>
                    </div>
                    <!--end::Sidebar toggle-->
                    <!--begin::Logo image-->
                    <default-brand></default-brand>
                    <!--end::Logo image-->
                </div>
                <!--end::Logo-->
                <!--begin::Header wrapper-->
                <div class="d-flex align-items-stretch justify-content-between flex-lg-grow-1"
                    id="kt_app_header_wrapper">
                    <!--begin::Menu wrapper-->
                    <div *ngIf="appSession.theme.isTopMenuUsed"
                        class="app-header-menu app-header-mobile-drawer align-items-stretch" data-kt-drawer="true"
                        data-kt-drawer-name="app-header-menu" data-kt-drawer-activate="{default: true, lg: false}"
                        data-kt-drawer-overlay="true" data-kt-drawer-width="225px" data-kt-drawer-direction="end"
                        data-kt-drawer-toggle="#kt_app_header_menu_toggle" data-kt-swapper="true"
                        data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
                        data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}">
                        <top-bar-menu></top-bar-menu>
                    </div>
                    <div *ngIf="!appSession.theme.isTopMenuUsed">&nbsp;</div>
                    <!--end::Menu wrapper-->
                    <!--begin::Navbar-->
                    <div class="app-navbar flex-shrink-0">
                        <active-delegated-users-combo *ngIf="!installationMode"></active-delegated-users-combo>
                        <header-notifications></header-notifications>
                        <user-menu></user-menu>
                    </div>
                    <!--end::Navbar-->
                </div>
                <!--end::Header wrapper-->
            </div>
            <!--end::Header container-->
        </div>
        <!--end::Header-->
        <!--begin::Wrapper-->
        <div class="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
            <!--begin::sidebar-->
            <div id="kt_app_sidebar" class="app-sidebar flex-column" data-kt-drawer="true"
                data-kt-drawer-name="app-sidebar" data-kt-drawer-activate="{default: true, lg: false}"
                data-kt-drawer-overlay="true" data-kt-drawer-width="225px" data-kt-drawer-direction="start"
                data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle">
                <!--begin::sidebar menu-->
                <div class="app-sidebar-menu overflow-hidden flex-column-fluid">
                    <!--begin::Menu wrapper-->
                    <div id="kt_app_sidebar_menu_wrapper" class="app-sidebar-wrapper my-5"
                        data-kt-scroll="true" data-kt-scroll-activate="true" data-kt-scroll-height="auto"
                        data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer"
                        data-kt-scroll-wrappers="#jrs_app_sidebar_menu" data-kt-scroll-offset="5px"
                        data-kt-scroll-save-state="true">
                        <side-bar-menu id="jrs_app_sidebar_menu"></side-bar-menu>
                    </div>
                    <!--end::Menu wrapper-->
                </div>
                <!--end::sidebar menu-->
            </div>
            <!--end::sidebar-->
            <!--begin::Main-->
            <div class="app-main flex-column flex-row-fluid" id="kt_app_main">
                <!--begin::Content wrapper-->
                <div class="d-flex flex-column flex-column-fluid pb-5">
                    <router-outlet></router-outlet>
                </div>
                <!--end::Content wrapper-->
                <!--begin::Footer-->
                <div id="kt_app_footer" class="app-footer">
                    <!--begin::Footer container-->
                    <footer-bar></footer-bar>
                    <!--end::Footer container-->
                </div>
                <!--end::Footer-->
            </div>
            <!--end:::Main-->
        </div>
        <!--end::Wrapper-->
    </div>
    <!--end::Page-->
</div>
