<div bsModal #notificationSettingsModal="bs-modal" (onShown)="onShown()" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="notificationSettingsModal" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form #taskForm="ngForm" novalidate (ngSubmit)="save()" autocomplete="off">
                <div class="modal-header">
                    <div>
                        <h4 class="modal-title">
                            Notification Subscription Settings
                        </h4>
                    </div>
                    <div>
                        <button type="button" class="btn btn-default" (click)="close()">{{"Cancel" | localize}}</button>
                        <button type="submit" class="btn btn-primary"> <span>{{"Save" | localize}}</span></button>
                    </div>


                    <!-- <button type="button" class="close" (click)="close()" [attr.aria-label]="l('Close')" [disabled]="saving">
                        <span aria-hidden="true">&times;</span>
                    </button> -->
                </div>
                <div id="notificationSettingsModaBody" class="modal-body">
                    <div *ngFor="let item of settingItems">
                        <label for="priorityAll{{item.name}}" class="m-checkbox mr-4" >

                            <input id="priorityAll{{item.name}}" type="checkbox" name="{{item.name}}"
                                [(ngModel)]="item.isSubscribed">{{item.name}}<span></span>
                        </label>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" (click)="close()">{{"Cancel" | localize}}</button>
                    <button type="submit" class="btn btn-primary" (click)="save()">
                        <span>{{"Save" | localize}}</span></button>
                </div>
            </form>
        </div>
    </div>
</div>
