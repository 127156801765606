import { Component, EventEmitter, Injector, Output, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { KeyValuePairOfNotificationTypeBoolean, NotificationServiceProxy, NotificationSubscriptionDto } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
    selector: 'notificationSubscriptionSettingsModal',
    templateUrl: './notification-settings-modal.component.html',
})
export class NotificationSubscriptionSettingsModalComponent extends AppComponentBase {

    @ViewChild('notificationSettingsModal', { static: true }) modal: ModalDirective;


    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
    @Output() findUser: EventEmitter<any> = new EventEmitter<any>();
    @Output() createList: EventEmitter<any> = new EventEmitter<any>();


    settingItems: NotificationSubscriptionDto[];

    constructor(
        injector: Injector,
        private _notificationService: NotificationServiceProxy
    ) {
        super(injector);
    }

    show(): void {
        this._notificationService.getSettingsForUser(this.appSession.user.publicId).subscribe(
            (res) => {
                this.settingItems = res;
            }
        );
        this.modal.show();
    }


    onShown(): void {
    }


    save() {
        this._notificationService.updateUserSubscription(this.appSession.user.publicId, this.settingItems.map((a) => new KeyValuePairOfNotificationTypeBoolean({ key: a.notificationType, value: a.isSubscribed }))).subscribe();
        this.close();
    }

    close(): void {
        this.modal.hide();
    }
}
