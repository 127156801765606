<div bsModal #changePictureModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="myLargeModalLabel" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form *ngIf="active" #changePictureModalForm="ngForm" (ngSubmit)="save()">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <span>{{headerText}}</span>
                    </h5>
                    <button type="button" class="close" [attr.aria-label]="l('Close')" (click)="close()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <input type="file" (change)="fileChangeEvent($event)" />
                        <span class="help-block m-b-none">{{helpInfo}}</span>
                    </div>
                    <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="maintainAspectRatio"
                        [resizeToWidth]="resizeToWidth" format="png"
                        (imageCroppedFile)="imageCroppedFile($event)"></image-cropper>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="close()"
                        [disabled]="saving">{{l("Cancel")}}</button>
                    <button type="submit" class="btn btn-primary" (click)="save()"
                        [disabled]="!changePictureModalForm.form.valid || saving"> <span>{{l("Save")}}</span></button>
                </div>
            </form>
        </div>
    </div>
</div>
