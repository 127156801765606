import { Injector, NgModule } from '@angular/core';
import { NavigationEnd, Router, RouterModule, Routes } from '@angular/router';
import { AppConsts } from '@shared/AppConsts';
import { AppUiCustomizationService } from '@shared/common/ui/app-ui-customization.service';
import { AuthenticateResultModel, TokenAuthServiceProxy } from '@shared/service-proxies/service-proxies';
import { TokenService, UtilsService } from 'abp-ng2-module';
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs';

const routes: Routes = [
    { path: '', redirectTo: '/app/admin/institutions', pathMatch: 'full' },
    {
        path: 'account',
        loadChildren: () => import('account/account.module').then((m) => m.AccountModule), //Lazy load account module
        data: { preload: true },
    },
    {
        path: 'dna-profile',
        loadChildren: () => import('dna-profile/dna-profile.module').then((m) => m.DnaProfileModule),
        data: { preload: true },
    },
    {
        path: 'assessment',
        loadChildren: () => import('assessment/assessment.module').then((m) => m.AssessmentModule),
        data: { preload: true },
    },
    {
        path: 'assessment-summary',
        loadChildren: () => import('assessment-summary/assessment-summary.module').then((m) => m.AssessmentSummaryModule),
        data: { preload: true },
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes,
        {
            // preloadingStrategy: DataBasedPreloadingStrategy,
            anchorScrolling: 'enabled',
            scrollPositionRestoration: 'enabled',
            scrollOffset: [0, 110] // [x, y]
        }),
    ],
    exports: [RouterModule],
    providers: [],
})
export class RootRoutingModule {
    constructor(
        private router: Router,
        private _uiCustomizationService: AppUiCustomizationService,
        private _injector: Injector,
    ) {
        router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                map((event: NavigationEnd) => event.url),
                distinctUntilChanged(),
                tap((url) => setTimeout(() => this.toggleBodyCssClass(url))),
                debounceTime(250)
            ).subscribe(() => this.refreshToken());
    }

    refreshToken(forse?: boolean) {
        const tokenAuthService = this._injector.get(TokenAuthServiceProxy);
        const tokenService = this._injector.get(TokenService);
        const utilsService = this._injector.get(UtilsService);

        let accessTokenExpireValue = localStorage.getItem('accessTokenExpire');
        if (!!accessTokenExpireValue) {
            let diffInMs: number = Date.parse(accessTokenExpireValue) - Date.now();
            let diffInHours: number = diffInMs / 1000 / 60 / 60;

            if (diffInHours < 0) {
                localStorage.removeItem('accessTokenExpire');
                localStorage.removeItem('refreshToken');

                return;
            }

            if (forse || diffInHours < 2) {
                let refreshTokenValue = localStorage.getItem('refreshToken');

                if (!!refreshTokenValue) {
                    tokenAuthService
                        .refreshToken(refreshTokenValue)
                        //.pipe(finalize(finallyCallback))
                        .subscribe((authenticateResult: AuthenticateResultModel) => {
                            let expireInSeconds = authenticateResult.expireInSeconds || (21600);
                            let tokenExpireDate = new Date(new Date().getTime() + 1000 * expireInSeconds);

                            tokenService.setToken(
                                authenticateResult.accessToken,
                                tokenExpireDate
                            );

                            localStorage.setItem('refreshToken', authenticateResult.refreshToken);
                            localStorage.setItem('accessTokenExpire', tokenExpireDate.toISOString());

                            utilsService.setCookieValue(
                                AppConsts.authorization.encrptedAuthTokenName,
                                authenticateResult.encryptedAccessToken,
                                tokenExpireDate,
                                abp.appPath
                            );
                        });
                }
            }
        }
    }

    toggleBodyCssClass(url: string): void {
        if (url) {
            if (url === '/') {
                if (abp.session.userId > 0) {
                    this.setAppModuleBodyClassInternal();
                } else {
                    this.setAccountModuleBodyClassInternal();
                }
            }

            if (url.indexOf('/account/') >= 0) {
                this.setAccountModuleBodyClassInternal();
            } else {
                this.setAppModuleBodyClassInternal();
            }
        }
    }

    setAppModuleBodyClassInternal(): void {
        let currentBodyClass = document.body.className;
        let classesToRemember = '';

        if (currentBodyClass.indexOf('brand-minimize') >= 0) {
            classesToRemember += ' brand-minimize ';
        }

        if (currentBodyClass.indexOf('aside-left-minimize') >= 0) {
            classesToRemember += ' aside-left-minimize';
        }

        if (currentBodyClass.indexOf('brand-hide') >= 0) {
            classesToRemember += ' brand-hide';
        }

        if (currentBodyClass.indexOf('aside-left-hide') >= 0) {
            classesToRemember += ' aside-left-hide';
        }

        if (currentBodyClass.indexOf('swal2-toast-shown') >= 0) {
            classesToRemember += ' swal2-toast-shown';
        }

        if (currentBodyClass.indexOf('toast-shown') >= 0) {
            classesToRemember += ' toast-shown';
        }

        document.body.className = this._uiCustomizationService.getAppModuleBodyClass() + ' ' + classesToRemember;
    }

    setAccountModuleBodyClassInternal(): void {
        let currentBodyClass = document.body.className;
        let classesToRemember = '';

        if (currentBodyClass.indexOf('swal2-toast-shown') >= 0) {
            classesToRemember += ' swal2-toast-shown';
        }

        if (currentBodyClass.indexOf('toast-shown') >= 0) {
            classesToRemember += ' toast-shown';
        }

        document.body.className = this._uiCustomizationService.getAccountModuleBodyClass() + ' ' + classesToRemember;
    }

    getSetting(key: string): string {
        return abp.setting.get(key);
    }
}
