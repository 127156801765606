<div class="d-flex align-items-center">
    <div [class]="togglerCssClass" id="kt_quick_user_toggle" data-kt-menu-trigger="click" data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end">
        <div [ngClass]="togglerCssClass" data-kt-menu-trigger="click" data-kt-menu-attach="parent"
            class="d-flex align-items-center justify-content-center" data-kt-menu-placement="bottom-end">
            <div class="m-topbar__username">
                <div>Journey</div>
                <div>System</div>
            </div>
            <img [src]="profilePicture" alt="user" [class]="profileImageCssClass" />
        </div>
    </div>

    <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold fs-6"
        data-kt-menu="true">
        <div class="user-menu__header bg-primary"
            style=" background: url('/assets/metronic/assets/demo-dev/default/media/img/misc/user_profile_bg.jpg') 0% 0% / cover;">
            <div class="menu-content d-flex align-items-center px-3">
                <div class="user-menu__profile-picture">
                    <img alt="Logo" [src]="profilePicture" />
                </div>
                <div class="d-flex flex-column">
                    <div class="user-menu__user-name">
                        <span *ngIf="isMultiTenancyEnabled" class="tenancy-name">{{ tenancyName }}\</span>
                        {{ fullName }}
                    </div>
                </div>
            </div>
        </div>

        <div class="user-menu__body">
            <!-- Back to My Account -->
            <ng-container *ngIf="isImpersonatedLogin">
                <div class="menu-item">
                    <a href="javascript:;" (click)="backToMyAccount()" class="menu-link">
                        <span class="menu-icon">
                            <i class="fa fa-reply"></i>
                        </span>
                        {{ 'BackToMyAccount' | localize }}
                    </a>
                </div>

                <div class="separator border-0 my-5"></div>
            </ng-container>
            <!-- Change Password -->
            <div class="menu-item">
                <a href="javascript:;" (click)="changePassword()" class="menu-link" id="UserProfileChangePasswordLink">
                    <span class="menu-icon">
                        <i class="flaticon-more-v6"></i>
                    </span>
                    {{ 'ChangePassword' | localize }}
                </a>
            </div>
            <!-- Profile Picture -->
            <div class="menu-item d-flex">
                <a href="javascript:;" (click)="changeProfilePicture()" class="menu-link"
                    id="UserProfileChangePictureLink">
                    <span class="menu-icon">
                        <i class="flaticon-profile-1"></i>
                    </span>
                    {{ 'ChangeProfilePicture' | localize }}
                </a>
            </div>
            <div class="menu-item d-flex">
                <a href="javascript:;" (click)="showDeleteActionToggle()" class="menu-link">
                    <span class="menu-icon">
                        <i class="jour-delete" style="font-size: 34px !important;margin: -9px;"></i>
                    </span>
                    <span *ngIf="!showDeleteActions">
                        {{"ShowDeleteActions" | localize}}
                    </span>
                    <span *ngIf="showDeleteActions">
                        {{"HideDeleteActions" | localize}}
                    </span>
                </a>
            </div>
            <div class="menu-item d-flex" *ngIf="permission.isGranted('Pages.Notifications')">
                <a href="javascript:;" (click)="notificationSettingsModalshow()" class="menu-link">
                    <span class="menu-icon">
                        <i class="flaticon-profile-1"></i>
                    </span>
                    {{"NotificationSettings" | localize}}
                </a>
            </div>
            <!-- My Settings -->
            <div class="menu-item d-flex">
                <a href="javascript:;" (click)="changeMySettings()" class="menu-link" id="UserProfileMySettingsLink">
                    <span class="menu-icon">
                        <i class="flaticon-cogwheel"></i>
                    </span>
                    {{ 'MySettings' | localize }}
                </a>
            </div>
            <div class="menu-item mt-5 d-flex">
                <a href="javascript:;" (click)="logout()"
                    class="btn m-btn--pill btn-secondary m-btn m-btn--custom m-btn--label-brand m-btn--bolder">
                    {{ 'Logout' | localize }}
                </a>
            </div>
        </div>
    </div>
</div>
