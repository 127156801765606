<div [class]="currentTheme.baseSettings.subHeader.containerStyle + ' toolbar'">
    <div class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap w-100">
        <div class="d-flex align-items-center flex-wrap me-2">
            <h1 *ngIf="currentTheme.baseSettings.subHeader.subheaderSize == 1"
                class="m-subheader__title {{currentTheme.baseSettings.subHeader.titleStyle}}"
                [ngClass]="{'subheader-separator': (!!description || breadcrumbs && breadcrumbs.length > 0)}">
                {{ title }}
            </h1>
            <h2 *ngIf="currentTheme.baseSettings.subHeader.subheaderSize == 2"
                class="m-subheader__title {{currentTheme.baseSettings.subHeader.titleStyle}}"
                [ngClass]="{'subheader-separator': (!!description || breadcrumbs && breadcrumbs.length > 0)}">
                {{ title }}
            </h2>
            <h3 *ngIf="currentTheme.baseSettings.subHeader.subheaderSize == 3"
                class="m-subheader__title {{currentTheme.baseSettings.subHeader.titleStyle}}"
                [ngClass]="{'subheader-separator': (!!description || breadcrumbs && breadcrumbs.length > 0)}">
                {{ title }}
            </h3>
            <h4 *ngIf="currentTheme.baseSettings.subHeader.subheaderSize == 4"
                class="m-subheader__title {{currentTheme.baseSettings.subHeader.titleStyle}}"
                [ngClass]="{'subheader-separator': (!!description || breadcrumbs && breadcrumbs.length > 0)}">
                {{ title }}
            </h4>
            <h5 *ngIf="currentTheme.baseSettings.subHeader.subheaderSize == 5"
                class="m-subheader__title {{currentTheme.baseSettings.subHeader.titleStyle}}"
                [ngClass]="{'subheader-separator': (!!description || breadcrumbs && breadcrumbs.length > 0)}">
                {{ title }}
            </h5>
            <h6 *ngIf="currentTheme.baseSettings.subHeader.subheaderSize == 6"
                class="m-subheader__title {{currentTheme.baseSettings.subHeader.titleStyle}}"
                [ngClass]="{'subheader-separator': (!!description || breadcrumbs && breadcrumbs.length > 0)}">
                {{ title }}
            </h6>
            <div *ngIf="description" class="d-flex align-items-center"
                [ngClass]="{'subheader-separator': breadcrumbs && breadcrumbs.length > 0}">
                {{ description }}
            </div>
            <ul *ngIf="breadcrumbs && breadcrumbs.length > 0"
                class="breadcrumb breadcrumb-transparent breadcrumb-dot fw-bold p-0 my-2 fs-sm">
                <li class="breadcrumb-item" *ngFor="let breadcrumbItem of breadcrumbs">
                    <a *ngIf="breadcrumbItem.isLink()" style="cursor: pointer" (click)="goToBreadcrumb(breadcrumbItem)"
                        class="text-muted">
                        {{ breadcrumbItem.text }}
                    </a>
                    <span *ngIf="!breadcrumbItem.isLink()" class="text-muted">{{ breadcrumbItem.text }}</span>
                </li>
            </ul>
        </div>
        <div class="d-flex align-items-center">
            <ng-content select="div[role=actions]"></ng-content>
        </div>
    </div>
</div>
