import { ZeroRefreshTokenService } from '@account/auth/zero-refresh-token.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AbpHttpConfigurationService, AbpHttpInterceptor, RefreshTokenService } from 'abp-ng2-module';
import * as ApiServiceProxies from './service-proxies';
import { ZeroTemplateHttpConfigurationService } from './zero-template-http-configuration.service';

@NgModule({
    providers: [
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.ChatServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.FriendshipServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.PaymentServiceProxy,
        ApiServiceProxies.DemoUiComponentsServiceProxy,
        ApiServiceProxies.InvoiceServiceProxy,
        ApiServiceProxies.SubscriptionServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.UiCustomizationSettingsServiceProxy,
        ApiServiceProxies.PayPalPaymentServiceProxy,
        ApiServiceProxies.StripePaymentServiceProxy,
        ApiServiceProxies.DashboardCustomizationServiceProxy,
        ApiServiceProxies.WebhookEventServiceProxy,
        ApiServiceProxies.WebhookSubscriptionServiceProxy,
        ApiServiceProxies.WebhookSendAttemptServiceProxy,
        ApiServiceProxies.UserDelegationServiceProxy,
        ApiServiceProxies.DynamicPropertyServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyDefinitionServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyServiceProxy,
        ApiServiceProxies.DynamicPropertyValueServiceProxy,
        ApiServiceProxies.DynamicEntityPropertyValueServiceProxy,
        ApiServiceProxies.TwitterServiceProxy,
        ApiServiceProxies.CompanyServiceProxy,
        ApiServiceProxies.CompanyUserServiceProxy,
        ApiServiceProxies.CompanyUserRequestAccessServiceProxy,
        ApiServiceProxies.WheelOfLifeServiceProxy,
        ApiServiceProxies.CoachingSessionsServiceProxy,
        ApiServiceProxies.DNAAssessmentServiceProxy,
        ApiServiceProxies.DNAProfileServiceProxy,
        ApiServiceProxies.CompanyUserDocumentsServiceProxy,
        ApiServiceProxies.CompanyDocumentsServiceProxy,
        ApiServiceProxies.FiveWhysServiceProxy,
        ApiServiceProxies.GainAndLossServiceProxy,
        ApiServiceProxies.SalServiceProxy,
        ApiServiceProxies.SalTaskServiceProxy,
        ApiServiceProxies.TeamChartServiceProxy,
        ApiServiceProxies.TeamChartStrategyServiceProxy,
        ApiServiceProxies.StatementTaskServiceProxy,
        ApiServiceProxies.CoachingSessions_V2ServiceProxy,
        ApiServiceProxies.DueDiligenceServiceProxy,

        ApiServiceProxies.AssessmentCategoryServiceProxy,
        ApiServiceProxies.AssessmentServiceProxy,
        ApiServiceProxies.ReviewStageServiceProxy,
        ApiServiceProxies.ScoreDescriptionServiceProxy,
        ApiServiceProxies.MessageBoardServiceProxy,
        ApiServiceProxies.PermanentRecommendationServiceProxy,
        ApiServiceProxies.AssessmentResultServiceProxy,
        ApiServiceProxies.AssessmentViewServiceProxy,
        ApiServiceProxies.AssessmentViewRuleServiceProxy,
        ApiServiceProxies.SepObservationZoneServiceProxy,
        ApiServiceProxies.SepAnnualPlannerServiceProxy,
        ApiServiceProxies.DashboardServiceProxy,
        ApiServiceProxies.SepIntakeFormServiceProxy,
        ApiServiceProxies.SepNinetyDayDecoServiceProxy,
        ApiServiceProxies.AnalyticsServiceProxy,
        ApiServiceProxies.MeSessionsServiceProxy,
        ApiServiceProxies.MattersOfImportanceServiceProxy,
        ApiServiceProxies.CompanyNoteServiceProxy,
        ApiServiceProxies.CompanyNoteActionServiceProxy,
        ApiServiceProxies.CompanyTeamServiceProxy,
        ApiServiceProxies.CompanyTeamUserServiceProxy,
        ApiServiceProxies.SalActionCheckListServiceProxy,
        ApiServiceProxies.PrivateJournalServiceProxy,
        ApiServiceProxies.WinsServiceProxy,
        ApiServiceProxies.SalActionDocumentsServiceProxy,
        { provide: RefreshTokenService, useClass: ZeroRefreshTokenService },
        { provide: AbpHttpConfigurationService, useClass: ZeroTemplateHttpConfigurationService },
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
    ],
})
export class ServiceProxyModule {}
